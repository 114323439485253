var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("b-breadcrumb", { attrs: { items: _vm.breadcrumbs } }),
      _c("Loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state === _vm.ViewState.Loading,
            expression: "state === ViewState.Loading"
          }
        ]
      }),
      _vm.state === _vm.ViewState.Loaded
        ? _c("div", [
            _c("h3", [_vm._v(_vm._s(_vm.notice.title))]),
            _c("p", [_vm._v(_vm._s(_vm.notice.body))])
          ])
        : _vm._e(),
      _vm.state === _vm.ViewState.Fatal
        ? _c("div", [
            _c("div", { staticClass: "text-center mt-5" }, [
              _c("h4", [_vm._v(_vm._s(_vm.state.title))]),
              _c("p", [_vm._v(_vm._s(_vm.state.message))])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }