<template>
  <div class="text-center">
    <b-spinner variant="secondary" class="m-5 large" label="Loading..."></b-spinner>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.large {
  width: 3rem;
  height: 3rem;
}
</style>