<template>
  <div class="main">
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <Loading v-show="state === ViewState.Loading" />
    <div v-if="state === ViewState.Loaded">
      <h3>{{ notice.title }}</h3>
      <p>{{ notice.body }}</p>
    </div>
    <div v-if="state === ViewState.Fatal">
      <div class="text-center mt-5">
        <h4>{{ state.title }}</h4>
        <p>{{ state.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';

const ViewState = {
  Default: {},
  Loading: {},
  Loaded: {},
  Fatal: { title: 'エラー', message: '恐れ入りますが、しばらく経ってから再度お試しください' },
}

export default {
  data() {
    return {
      ViewState: ViewState,
      state: ViewState.Default,
      breadcrumbs: [
        { text: "HOME", to: { name: "Home" } },
        { text: "お知らせ", active: true },
      ],
      noticeId: this.$route.params.id,
      notice: {
        title: "",
        body: "",
      },
    };
  },
  components: {
    Loading,
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.state = ViewState.Loading
      await this.axios.get(`/api/notices/${this.noticeId}`).then((response) => {
        this.notice = response.data
        this.state = ViewState.Loaded
      }).catch(() => {
        this.state = ViewState.Fatal
      })
    },
  },
}
</script>

<style scoped>
.main {
  margin: 20px;
}
</style>
